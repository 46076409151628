import React, { useState, useEffect } from "react"
import axios from "axios"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap"
import Select from "react-select"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { useNavigate } from "react-router-dom"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"

// (1) Importamos jspdf y autotable
import jsPDF from "jspdf"
import autoTable from "jspdf-autotable"

const baseURL = process.env.REACT_APP_API_URL

const GenerarExcel = props => {
  const navigate = useNavigate()

  // States principales
  const [empresas, setEmpresas] = useState([])
  const [documentos, setDocumentos] = useState([])

  const [tipoReporte, setTipoReporte] = useState("") // "facturasGeneradas" | "foliosFaltantes"
  const [nitemisor, setNitemisor] = useState("")
  const [fechaInicio, setFechaInicio] = useState(null)
  const [fechaFin, setFechaFin] = useState(null)
  const [tipoDocumento, setTipoDocumento] = useState("TODOS LOS DOCUMENTOS")

  const [sucursales, setSucursales] = useState([])
  const [selectedSucursal, setSelectedSucursal] = useState("")
  const [selectedSucursalId, setSelectedSucursalId] = useState("")

  const [prefijos, setPrefijos] = useState([])
  const [selectedPrefijo, setSelectedPrefijo] = useState("")

  const [loading, setLoading] = useState(false) // Para indicar que cargan sucursales/prefijos
  const [generating, setGenerating] = useState(false) // Para indicar que estamos generando reporte

  // useEffect que carga datos iniciales (empresas, tipos de documento, etc.)
  useEffect(() => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (currentUser) {
      const userGreeting = `Bienvenido, ${
        currentUser
          ? `${currentUser.nombre} ${currentUser.apellido}`
          : "Usuario"
      }`
      const breadcrumbItems = [
        { title: "LS-DA", link: "#" },
        { title: "Generar Reportes", link: "#" },
      ]
      props.setBreadcrumbItems(userGreeting, breadcrumbItems)
    }
    fetchTipoDocumento()
    fetchEmpresas()
  }, [])

  // ====================
  //   LÓGICA DE NEGOCIO
  // ====================

  // (A) Validar que el rango no supere 1 mes
  const isWithinOneMonth = (start, end) => {
    if (!start || !end) return true
    const diffInMs = end.getTime() - start.getTime()
    const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
    return diffInDays <= 31
  }

  // (B) Generar PDF de Facturas Generadas
  const generateFacturasPDF = data => {
    // data = response del endpoint /recupera (p.ej. un objeto con info de archivos generados)
    const doc = new jsPDF()
    doc.text("Reporte de Facturas Generadas", 14, 15)

    // Suponiendo que data tiene { nombre, fecha, url, ... }
    // Ajusta según la estructura real. Si es un array, recórrelo.
    const rows = []

    // Si la respuesta es un solo objeto, conviértelo en array
    // o si ya es array, ajústalo. Aquí asumo que data podría ser un array.
    const archivos = Array.isArray(data) ? data : [data]

    archivos.forEach((archivo, idx) => {
      rows.push([
        idx + 1,
        archivo.nombre || "",
        archivo.fecha ? new Date(archivo.fecha).toLocaleString() : "",
      ])
    })

    autoTable(doc, {
      startY: 25,
      head: [["#", "Nombre Archivo", "Fecha"]],
      body: rows,
    })

    doc.save("FacturasGeneradas.pdf")
  }

  // (C) Generar PDF de Folios Faltantes
  const generateFoliosPDF = data => {
    // data = { missingFolios: [ { prefijo, folios: [n1, n2] }, ... ] }
    const doc = new jsPDF()
    doc.text("Reporte de Folios Faltantes", 14, 15)

    // Encabezado de la empresa
    doc.setFontSize(10)
    //doc.text(`Emisor: ${`${currentUser.nombre} ${currentUser.apellido}`}`, 14, 25)
    doc.text("Generado por LS-DA S.A.S", 14, 32)

    // Opcionalmente, podrías mostrar fecha/hora actual
    doc.text(`Fecha de reporte: ${new Date().toLocaleString()}`, 14, 39)

    if (data && Array.isArray(data.missingFolios)) {
      const rows = []
      data.missingFolios.forEach(item => {
        const { prefijo, folios } = item
        rows.push([
          prefijo,
          folios.join(", "), // convertir el array de folios a string
        ])
      })

      autoTable(doc, {
        startY: 25,
        head: [["Prefijo", "Folios Faltantes"]],
        body: rows,
      })
    }

    doc.save("FoliosFaltantes.pdf")
  }

  // =====================
  //   HANDLER PRINCIPAL
  // =====================
  const handleSubmit = async e => {
    e.preventDefault()
    setGenerating(true)

    // Validar rango de 1 mes
    if (!isWithinOneMonth(fechaInicio, fechaFin)) {
      alert("El rango de fechas no puede superar 1 mes.")
      setGenerating(false)
      return
    }

    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    try {
      if (tipoReporte === "facturasGeneradas") {
        // Consumir /recupera
        const response = await axios.get(`${baseURL}invoice/recupera`, {
          params: {
            nitemisor,
            fechaInicio: fechaInicio
              ? fechaInicio.toISOString().split("T")[0]
              : "",
            fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
            tipoDocumento:
              tipoDocumento !== "TODOS LOS DOCUMENTOS"
                ? tipoDocumento
                : undefined,
          },
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        })
        // GENERAR PDF AUTOMÁTICAMENTE
        generateFacturasPDF(response.data)
      } else if (tipoReporte === "foliosFaltantes") {
        // Consumir /foliosfaltantes
        const response = await axios.get(`${baseURL}invoice/foliosfaltantes`, {
          params: {
            nit: nitemisor,
            fechaInicio: fechaInicio
              ? fechaInicio.toISOString().split("T")[0]
              : "",
            fechaFin: fechaFin ? fechaFin.toISOString().split("T")[0] : "",
            prefijo: selectedPrefijo || undefined,
          },
          headers: {
            Authorization: `Bearer ${currentUser.access}`,
          },
        })
        // GENERAR PDF AUTOMÁTICAMENTE
        generateFoliosPDF(response.data)
      } else {
        alert("Por favor, seleccione un tipo de reporte.")
      }
    } catch (error) {
      console.error("Error generando reporte:", error)
    } finally {
      setGenerating(false)
    }
  }

  // ==================================
  //   LLAMADAS A LA API (FETCH)
  // ==================================
  const fetchTipoDocumento = async () => {
    try {
      const response = await axios.get("/listas/TipoDocumento-2.1.gc")
      const xmlText = response.data
      const parser = new DOMParser()
      const xmlDoc = parser.parseFromString(xmlText, "application/xml")
      const rows = xmlDoc.getElementsByTagName("Row")
      const types = []

      for (let row of rows) {
        const codeValue = row.querySelector('[ColumnRef="code"] SimpleValue')
        const nameValue = row.querySelector('[ColumnRef="name"] SimpleValue')
        if (codeValue && nameValue) {
          types.push({
            code: codeValue.textContent,
            name: nameValue.textContent,
          })
        }
      }

      setDocumentos(types)
    } catch (error) {
      console.error("Error fetching document types:", error)
    }
  }

  const fetchEmpresas = async () => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null

    if (!currentUser) {
      console.log("User data not fully available yet, skipping fetch.")
      return
    }

    if (currentUser.role === "SuperAdmin") {
      if (!currentUser.IdEmpresa || !currentUser.access) return
      try {
        const response = await axios.get(
          `${baseURL}empresas/obtener?id=${currentUser.IdEmpresa}`,
          {
            headers: { Authorization: `Bearer ${currentUser.access}` },
          },
        )
        setEmpresas(response.data)
      } catch (error) {
        console.error("Error fetching empresas data:", error)
      }
    } else {
      if (!currentUser.empresas) return
      try {
        setEmpresas(currentUser.empresas)
      } catch (error) {
        console.error("Error setting empresas data:", error)
      }
    }
  }

  const fetchSucursales = async nit => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (!currentUser || !currentUser.access) return

    setLoading(true)
    try {
      const response = await axios.get(
        `${baseURL}empresas/sucursales?id=${nit}`,
        {
          headers: { Authorization: `Bearer ${currentUser.access}` },
        },
      )
      setSucursales(response.data.sucursales || [])
    } catch (error) {
      console.error("Error fetching sucursales data:", error)
    } finally {
      setLoading(false)
    }
  }

  const fetchPrefijos = async sucursalId => {
    const storedUser = localStorage.getItem("authUser")
    const currentUser = storedUser ? JSON.parse(storedUser) : null
    if (!currentUser || !currentUser.access) return

    setLoading(true)
    try {
      const response = await axios.get(`${baseURL}resoluciones/prefijos`, {
        params: { sucursal: sucursalId },
        headers: { Authorization: `Bearer ${currentUser.access}` },
      })
      setPrefijos(response.data.prefijos || [])
    } catch (error) {
      console.error("Error fetching prefijos data:", error)
      setPrefijos([])
    } finally {
      setLoading(false)
    }
  }

  // MANEJO DE CAMBIOS EN SELECTS
  const storedUser2 = localStorage.getItem("authUser")
  const currentUser2 = storedUser2 ? JSON.parse(storedUser2) : null

  const empresaOptions = empresas.map(empresa => {
    let value, label
    if (currentUser2 && currentUser2.role === "SuperAdmin") {
      value = empresa.identificacion
      label = empresa.nombre
    } else {
      value = empresa.idempresa.identificacion
      label = empresa.idempresa.nombre
    }
    return { value, label }
  })

  const handleNitemisorChange = selectedOption => {
    setNitemisor(selectedOption ? selectedOption.value : "")
    setSelectedSucursal("")
    setSelectedSucursalId("")
    setSelectedPrefijo("")
    if (selectedOption) {
      fetchSucursales(selectedOption.value)
    } else {
      setSucursales([])
      setPrefijos([])
    }
  }

  const sucursalOptions = [
    { value: "", label: "Todos" },
    ...sucursales.map(s => ({ value: s._id, label: s.Nombretienda })),
  ]
  const handleSucursalChange = selectedOption => {
    setSelectedSucursal(selectedOption ? selectedOption.label : "")
    setSelectedSucursalId(selectedOption ? selectedOption.value : "")
    setSelectedPrefijo("")
    if (selectedOption && selectedOption.value) {
      fetchPrefijos(selectedOption.value)
    } else {
      setPrefijos([])
    }
  }

  const prefijoOptions = [
    { value: "", label: "Todos" },
    ...prefijos.map(p => ({
      value: p.prefijo,
      label: `${p.prefijo} - ${p.tipodocumento}`,
    })),
  ]

  // ============================
  //   RENDER (SIN TABLAS)
  // ============================
  return (
    <Row>
      <Col className="col-12">
        <Card>
          <CardBody>
            <div className="d-flex justify-content-between align-items-center mb-3">
              <CardTitle className="h4">Generar Reporte</CardTitle>
              <Button
                color="danger"
                onClick={() => navigate("/portal/dashboard")}
              >
                Salir
              </Button>
            </div>
            <p className="card-title-desc">
              Seleccione filtros y genere automáticamente un PDF.
            </p>
            <hr />

            {/* Mostrar Spinner cuando generating es true */}
            {generating && (
              <div
                className="mb-3"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Spinner color="primary" />
                <span style={{ marginLeft: "10px" }}>
                  Procesando reporte...
                </span>
              </div>
            )}

            {/* Formulario de Filtros */}
            <Form onSubmit={handleSubmit} inline>
              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Label for="tipoReporte">Tipo de Reporte</Label>
                    <Input
                      type="select"
                      id="tipoReporte"
                      value={tipoReporte}
                      onChange={e => setTipoReporte(e.target.value)}
                      required
                    >
                      <option value="">Seleccione un tipo</option>
                      <option value="facturasGeneradas">
                        Facturas Generadas
                      </option>
                      <option value="foliosFaltantes">Folios Faltantes</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="nitemisor">NIT Emisor</Label>
                    <Select
                      id="nitemisor"
                      value={empresaOptions.find(
                        opt => opt.value === nitemisor,
                      )}
                      onChange={handleNitemisorChange}
                      options={empresaOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Seleccione una empresa"
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="sucursales">Sucursales</Label>
                    <Select
                      id="sucursales"
                      value={sucursalOptions.find(
                        opt => opt.label === selectedSucursal,
                      )}
                      onChange={handleSucursalChange}
                      options={sucursalOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Seleccione una sucursal"
                      isClearable
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="prefijo">Prefijo</Label>
                    <Select
                      id="prefijo"
                      value={prefijoOptions.find(
                        opt => opt.value === selectedPrefijo,
                      )}
                      onChange={opt => setSelectedPrefijo(opt ? opt.value : "")}
                      options={prefijoOptions}
                      classNamePrefix="select2-selection"
                      placeholder="Seleccione un prefijo"
                      isClearable
                      isDisabled={prefijoOptions.length === 0}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fechaInicio">Fecha Inicio</Label>
                    <DatePicker
                      selected={fechaInicio}
                      onChange={date => setFechaInicio(date)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Label for="fechaFin">Fecha Fin</Label>
                    <DatePicker
                      selected={fechaFin}
                      onChange={date => setFechaFin(date)}
                      dateFormat="yyyy-MM-dd"
                      className="form-control"
                      required
                    />
                  </FormGroup>
                </Col>

                {/* Solo muestra combo Tipo Documento si es facturasGeneradas */}
                {tipoReporte === "facturasGeneradas" && (
                  <Col md={3}>
                    <FormGroup>
                      <Label for="tipoDocumento">Tipo Documento</Label>
                      <Input
                        type="select"
                        id="tipoDocumento"
                        value={tipoDocumento}
                        onChange={e => setTipoDocumento(e.target.value)}
                        required
                      >
                        <option value="TODOS LOS DOCUMENTOS">
                          Todos los Documentos
                        </option>
                        {documentos.map(type => (
                          <option key={type.code} value={type.code}>
                            {type.name}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                )}

                <Col
                  md={2}
                  className="d-flex align-items-end justify-content-end"
                >
                  <Button type="submit" color="primary" disabled={generating}>
                    {generating ? "Procesando..." : "Generar"}
                  </Button>
                </Col>
              </Row>
            </Form>

            <hr />
            {/* Si loading es true cuando cargamos sucursales/prefijos */}
            {loading && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <Spinner color="secondary" />
                <span style={{ marginLeft: "10px" }}>Cargando...</span>
              </div>
            )}
            {/* NO MOSTRAMOS NINGUN DATO, POR REQUERIMIENTO */}
          </CardBody>
        </Card>
      </Col>
    </Row>
  )
}

const mapStateToProps = state => {
  return {
    authData: state.Login.user,
  }
}

export default connect(mapStateToProps, { setBreadcrumbItems })(GenerarExcel)
